var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',[_c('v-col',{staticClass:"pb-0",attrs:{"cols":"12"}},[_c('v-data-table',{attrs:{"page":_vm.customersPage$,"headers":_vm.tableHeader,"items":_vm.tableItems,"fixed-header":"","hide-default-footer":"","disable-sort":"","disable-pagination":"","options":_vm.options,"server-items-length":_vm.serverItemsLength,"no-data-text":"Nessun dato disponibile","no-results-text":"Nessuna cliente trovato","height":"350","item-key":"customerId"},on:{"update:page":function($event){_vm.customersPage$=$event},"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('tr',[_c('td',[_vm._v(_vm._s(item.customerName))]),_c('td',[_vm._v(_vm._s(item.companyName))]),_c('td',[_vm._v(_vm._s(item.customerGesivId))]),_c('td',[_c('v-checkbox',{key:item.customerId,staticClass:"ma-0 pt-0",attrs:{"value":item.customerId,"dense":"","hide-details":"","color":"var(--v-secondary-darken1)","readonly":_vm.disableCheckbox === item.customerId,"disabled":_vm.disableCheckbox !== item.customerId &&
                _vm.disableCheckbox !== null},on:{"click":function($event){return _vm.toggleSelected(item)}},model:{value:(_vm.checkboxModel),callback:function ($$v) {_vm.checkboxModel=$$v},expression:"checkboxModel"}})],1)])]}},{key:"footer",fn:function(footer){return [_c('v-row',{staticClass:"users-card_footer",attrs:{"align":"center","justify":"end"}},[_c('v-col',{staticClass:"c-label-regular",attrs:{"cols":"auto"},domProps:{"textContent":_vm._s(_vm.footerText(footer.props.pagination))}}),_c('v-col',{attrs:{"cols":"auto"}},[_c('v-icon',{attrs:{"disabled":footer.props.pagination.page < 2},domProps:{"textContent":_vm._s('mdi-chevron-left')},on:{"click":function($event){return _vm.prev(footer.props.pagination.page)}}})],1),_c('v-col',{attrs:{"cols":"auto"}},[_c('v-icon',{attrs:{"disabled":footer.props.pagination.page >=
                footer.props.pagination.pageCount},domProps:{"textContent":_vm._s('mdi-chevron-right')},on:{"click":function($event){return _vm.next(
                  footer.props.pagination.page,
                  footer.props.pagination.pageCount
                )}}})],1)],1)]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }