














































































import { Component, Prop, PropSync, Vue } from "vue-property-decorator";
import { Customer } from "@/models/entities/customer.interface";

@Component
export default class UserCustomersCard extends Vue {
  @PropSync("customersPage", { required: true, type: Number })
  customersPage$: number;
  @PropSync("selectedCustomers", {
    required: false,
    default: () => [],
    type: Array,
  })
  private selectedCustomers$: Customer[];
  @Prop({ required: true, default: null, type: Array })
  private customers: Customer[];
  @Prop({ required: false, default: null, type: Number })
  private disableCheckbox: number;
  @Prop({ required: true, type: Boolean })
  private onlySelected: boolean;
  @Prop({ required: false, default: 0, type: Number })
  private customersNr: number;
  @Prop({ required: false, default: "", type: String })
  private search: string;

  checkboxModel: number[] = [];
  private tableHeader = [
    { text: "Cliente", value: "customerName" },
    { text: "Azienda", value: "companyName" },
    { text: "Codice", value: "customerGesivId" },
    { text: "", value: "action", sortable: false },
  ];

  get options(): Record<string, number> {
    return { page: this.customersPage$, itemsPerPage: this.itemsPerPage };
  }
  set options(val: Record<string, number>) {
    this.customersPage$ = val.page;
  }

  get tableItems(): Customer[] {
    if (!this.onlySelected) return this.customers;
    if (!this.search) return this.selectedCustomers$;
    const s = this.search.trim().toLowerCase();
    return this.selectedCustomers$.filter((c) =>
      `${c.customerName}${c.customerGesivId}${c.companyName}`
        .toLowerCase()
        .includes(s)
    );
  }

  get serverItemsLength(): number {
    return this.onlySelected ? this.tableItems?.length : this.customersNr;
  }

  get itemsPerPage(): number {
    return this.onlySelected ? this.tableItems.length : this.customers.length;
  }

  created(): void {
    this.checkboxModel = this.selectedCustomers$.map((c) => c.customerId);
  }

  footerText(pagination: Record<string, unknown>): string {
    return `${pagination.pageStart}-${pagination.pageStop} di ${pagination.itemsLength}`;
  }

  prev(page: number): void {
    if (page > 1) {
      this.customersPage$--;
      if (!this.onlySelected) {
        this.$nextTick(() => this.$emit("reload:customers"));
      }
    }
  }

  next(page: number, pageCount: number): void {
    if (page < pageCount) {
      this.customersPage$++;
      if (!this.onlySelected) {
        this.$nextTick(() => this.$emit("reload:customers"));
      }
    }
  }

  toggleSelected(customer: Customer): void {
    const idx = this.selectedCustomers$.findIndex(
      (c) => c.customerId === customer.customerId
    );

    idx > -1
      ? this.selectedCustomers$.splice(idx, 1)
      : this.selectedCustomers$.push(customer);
  }
}
